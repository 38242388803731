import React, { Component } from 'react';
import "./Header.css";
import CKLogo from '../../images/CK-Logo.jpg';


class RegHeader extends Component {

    homePage = () => {
        window.location.href= '/form';
    }

    render() {
        return (
            <div>
            <div className="col-12 col-sm-9 reg-headTabsBg">
                <img src={CKLogo} onClick={this.homePage} className="col-6 col-sm-8 reg-samplebox-logo" alt="" />
                {/*<div className={"col-2 col-sm-1 mt-2 mt-sm-5 m-auto"}>*/}
                {/*    <a className={"reg-menuLink "} onClick={this.homePage}>Home</a>*/}
                {/*</div>*/}
                {/* <div className={"col-3 col-sm-2 mt-2 mt-sm-5 reg-headMenu"}>
                    <a className={"reg-menuLink "} onClick={this.navigate}>About Us</a>
                </div> */}
            </div>
            <div className='blueLine'>

            </div>
            </div>
        );
    }
}

export default RegHeader;
