import React, {Component} from "react";
import Camera from "react-html5-camera-photo";
import axios from "axios";
import "react-html5-camera-photo/build/css/index.css";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import "../Formview/Form.css";
import Header from "../Header/Header";
import Qna from "../../images/qna.png";
import {API_URL, userrole} from "../util/Constants";
import Modal from 'react-bootstrap/Modal';
// import Toast from 'react-bootstrap/Toast';
// import ToastContainer from 'react-bootstrap/ToastContainer';
import deleteicon from "../../images/deleteicon.png";
import undo from "../../images/undo.jpg";

const FormContents = {
    formName: "Line Clearance", basicQuestion: "Yes", question: [{
        questionName: "Last Product & SKU run in machine", questionType: "String", Answer: "",
    }, {
        questionName: "Change over Product & SKU",
        questionType: "Dropdown",
        Answer: "",
        options: [{option: "NYLE A.H.F soft and shiny 400ml"}, {option: "NYLE A.H.F soft and shiny 800ml"},],
    }, {
        questionName: "Is there any PM (Containers. Caps & CFCs) of previous product present in line",
        questionType: "Radio",
        Answer: "",
        options: [{option: "Yes"}, {option: "No"}],
    }, {
        questionName: "Date of maintainence", questionType: "Date", Answer: "",
    }, {
        questionName: "Machine start time", questionType: "Time", Answer: "",
    }, // {
        //     questionName: 'Machine start time Image',
        //     questionType: 'Image',
        //     Answer: ''
        // }
        // , {
        //     questionName: 'Machine end time Image',
        //     questionType: 'Image',
        //     Answer: ''
        // }
    ],
};

class Answers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            i: 1, question: [{}], index: 0, show: false, deleteIndex: '', // showToast: false,
        };
    }

    componentDidMount() {
        if (userrole) {
            const formId = localStorage.getItem("formId");
            this.getFormContents(formId);
            console.log(formId);
        } else {
            window.location.href = "/error";
        }

    }

    onSubmitAnswers = (e) => {
        e.preventDefault();
        if (this.state.FormContents.basicQuestion == 'true') {
            if (!this.state.FormContents.selectedDate || this.state.FormContents.selectedDate == '' || !this.state.FormContents.startTime || this.state.FormContents.startTime == '' || !this.state.FormContents.endTime || this.state.FormContents.endTime == '' || !this.state.FormContents.shift || this.state.FormContents.shift == '' || !this.state.FormContents.machineNo || this.state.FormContents.machineNo == '') {
                const state = this.state;
                state.error = 'error';
                this.setState(state);
                return;
            }
        }
        for (let x of this.state.FormContents.question) {
            if (!x.answer || x.answer == '') {
                const state = this.state;
                state.error = 'error';
                this.setState(state);
                return;
            }
        }

        const state = this.state;
        state.error = '';
        this.setState(state);
        console.log(this.state.FormContents);
        let postObj = {};
        let currentdate = new Date();
        let date = currentdate.getFullYear() + "-" + String(currentdate.getMonth() + 1).padStart(2, "0") + "-" + String(currentdate.getDate()).padStart(2, "0") + " " + String(currentdate.getHours()).padStart(2, "0") + ":" + String(currentdate.getMinutes()).padStart(2, "0") + ":" + String(currentdate.getSeconds()).padStart(2, "0");
        postObj.timestamp = date;
        postObj.formName = this.state.FormContents.formName;
        postObj.formId = this.state.FormContents.formId;
        postObj.branchId = this.state.FormContents.branchId;
        postObj.branchName = this.state.FormContents.branchName;
        postObj.basicQuestion = this.state.FormContents.basicQuestion;
        postObj.qna = this.state.FormContents.question;
        postObj.operatorName = localStorage.getItem("userMail");
        if (postObj.basicQuestion === "true") {
            postObj.selectedDate = this.state.FormContents.selectedDate;
            postObj.startTime = this.state.FormContents.startTime;
            postObj.endTime = this.state.FormContents.endTime;
            postObj.shift = this.state.FormContents.shift;
            postObj.machineNo = this.state.FormContents.machineNo;
        } else {
            postObj.selectedDate = "";
            postObj.startTime = "";
            postObj.endTime = "";
            postObj.shift = "";
            postObj.machineNo = "";
        }

        console.log(postObj);
        axios
            .post(API_URL + "createanswer", postObj)
            .then((data) => {
                console.log(data);
                console.log(data.status);
                if (data.status === 200) {
                    console.log("successmsg");
                    window.location.href = '/form';
                } else {
                    window.location.href = "/error"
                }
            })
            .catch((error) => {
                console.error(error);
                window.location.href = "/error"
            });
    };

    delete = (index) => {
        console.log(index);
        console.log(this.state.FormContents.question);
        const state = this.state;
        state.FormContents.question[index].deleted = '1';
        this.setState(state);
        // this.state.FormContents.question.splice(index, 1);
        console.log(this.state.FormContents);
        axios.put(API_URL + 'update', this.state.FormContents)
            .then(data => {
                console.log(data);
                console.log(data.status);
                if (data.status === 200) {
                    console.log("successmsg");
                    const state = this.state;
                    state.FormContents = data.data;
                    // state.showToast = true;
                    this.setState(state);
                    console.log(this.state.FormContents);

                } else {

                }
            }).catch((error) => {
            console.error(error)
        })
    };


    delete1 = (index) => {
        console.log(index);
        console.log(this.state.FormContents.question);
        const state = this.state;
        state.FormContents.question[index].deleted = '0';
        this.setState(state);
        // this.state.FormContents.question.splice(index, 1);
        console.log(this.state.FormContents);
        axios.put(API_URL + 'update', this.state.FormContents)
            .then(data => {
                console.log(data);
                console.log(data.status);
                if (data.status === 200) {
                    console.log("successmsg");
                    const state = this.state;
                    state.FormContents = data.data;
                    // state.showToast = true;
                    this.setState(state);
                    console.log(this.state.FormContents);

                } else {

                }
            }).catch((error) => {
            console.error(error)
        })
    };

    getFormContents = (formId) => {
        axios
            .get(API_URL + "id/" + formId)
            .then((data) => {
                console.log(data.data);
                console.log(data.status);
                if (data.status === 200) {
                    const state = this.state;
                    state.FormContents = data.data;
                    this.setState(state);
                    console.log(this.state.FormContents);
                } else {
                }
            })
            .catch((error) => {
                console.error(error);
                // window.location.href = "/error"
            });
    };

    onChange = (qindex) => (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        const state = this.state.FormContents.question[qindex];
        state[e.target.name] = e.target.value;
        this.setState(state);
        console.log(this.state);
        if (this.state.FormTitl) {
            console.log(this.state.FormContents[this.state.i - 1]);
            const state = this.state.FormContents[this.state.i - 1];
            state[e.target.name] = e.target.value;
            this.setState(state);
            console.log(this.state);
            return;
            if (e.target.id === "others") {
                const state = this.state;
                state[e.target.name + "Others"] = "others";
                state[e.target.name] = e.target.value;
                this.setState(state);
                console.log(this.state);
            } else {
                const state = this.state;
                state[e.target.name + "Others"] = "";
                state[e.target.name] = e.target.value;
                this.setState(state);
                console.log(this.state);
            }
        } else {
            console.log("Fill the title");
        }
    };


    onChangeRadio = (qindex, option) => (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        const state = this.state.FormContents.question[qindex];
        state['answer'] = option;
        this.setState(state);
        console.log(this.state);
        if (this.state.FormTitl) {
            console.log(this.state.FormContents[this.state.i - 1]);
            const state = this.state.FormContents[this.state.i - 1];
            state[e.target.name] = e.target.value;
            this.setState(state);
            console.log(this.state);
            return;
            if (e.target.id === "others") {
                const state = this.state;
                state[e.target.name + "Others"] = "others";
                state[e.target.name] = e.target.value;
                this.setState(state);
                console.log(this.state);
            } else {
                const state = this.state;
                state[e.target.name + "Others"] = "";
                state[e.target.name] = e.target.value;
                this.setState(state);
                console.log(this.state);
            }
        } else {
            console.log("Fill the title");
        }
    };

    onChangeTable = (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        const state = this.state.FormContents;
        state[e.target.name] = e.target.value;
        this.setState(state);
        console.log(this.state);
    };


    openCamera = (qindex) => {
        const state = this.state;
        state.qindex = qindex;
        state.openCamera = true;
        this.setState(state);
        console.log(this.state);
    };

    handleTakePhoto(dataUri) {
        // Do stuff with the photo...
        console.log(dataUri);
        console.log("takePhoto");
    }

    editPage() {
        window.location.href = "/edit"
    }

    handleClose = () => {
        const state = this.state;
        state.show = false;
        this.setState(state);
    }

    handleClose1 = () => {
        const state = this.state;
        state.show1 = false;
        this.setState(state);
    }

    handleSaveClose = () => {
        console.log(this.state.deleteIndex);
        this.delete(this.state.deleteIndex);
        const state = this.state;
        state.show = false;
        this.setState(state);
    }

    handleSaveClose1 = () => {
        console.log(this.state.deleteIndex);
        this.delete1(this.state.deleteIndex);
        const state = this.state;
        state.show1 = false;
        this.setState(state);
    }

    handleOpen = (index) => {
        const state = this.state;
        state.deleteIndex = index;
        state.show = true;
        this.setState(state);
    }

    handleOpen1 = (index) => {
        const state = this.state;
        state.deleteIndex = index;
        state.show1 = true;
        this.setState(state);
    }

    // setShowFalse = () =>{
    //     const state = this.state;
    //     state.showToast = false;
    //     this.setState(state);
    // }

    render() {
        return (<div className="mainDiv">
            <Header/>
            {
                this.state.FormContents && (
                    <div className="col-12 bg-colorForm1">
                        <div className="col-6 titleContent">
                            <h4>{this.state.FormContents.formName}</h4>
                            <p>Please take few minutes to fill these details.</p>
                        </div>
                        <div className="col-6 imgcls">
                            <img src={Qna} className="qnaImg" alt=""/>
                        </div>
                    </div>)
            }
            {
                this.state.FormContents && this.state.FormContents.basicQuestion === "true" && (
                    <div className="container bg-colorFormTable">
                        <div className="blueLine"></div>
                        <table style={{marginBottom: "0px"}}>
                            <tr>
                                <td>Date</td>
                                <td colSpan={3}>
                                    <input
                                        className="form-check-input inputForm"
                                        type="date"
                                        name="selectedDate"
                                        id="selectedDate"
                                        onChange={this.onChangeTable}
                                    />
                                </td>
                                <td>Start time</td>
                                <td>
                                    <input
                                        className="form-check-input inputForm"
                                        type="time"
                                        placeholder="Enter Question"
                                        name="startTime"
                                        id="startTime"
                                        onChange={this.onChangeTable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Shift</td>
                                <td colSpan={3} style={{width: "50%"}}>
                                    <input
                                        className="form-check-input inputForm"
                                        type="text"
                                        placeholder="Enter Shift"
                                        name="shift"
                                        id="shift"
                                        onChange={this.onChangeTable}
                                    />
                                </td>
                                <td>End time</td>
                                <td style={{width: "20%"}}>
                                    <input
                                        className="form-check-input inputForm"
                                        type="time"
                                        placeholder="Enter Question"
                                        name="endTime"
                                        id="endTime"
                                        onChange={this.onChangeTable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Machine No.</td>
                                <td colSpan={5}>
                                    <input
                                        className="form-check-input inputForm"
                                        type="text"
                                        placeholder="Enter Machine no."
                                        name="machineNo"
                                        id="machineNo"
                                        onChange={this.onChangeTable}
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>)}
            <div className="backgroundColorDiv">
                <div className="container  bg-colorForm">
                    {userrole === 'admin' && <div className="row">
                        <div className="col-12" style={{marginTop: "10px"}}>
                            <div className="" style={{float: "right"}}>
                                <Button
                                    variant="primary"
                                    className="primaryButton"
                                    type="submit"
                                    onClick={this.editPage}
                                >
                                    Edit Form
                                </Button>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="form-class">
                        {
                            this.state.FormContents && this.state.FormContents.question.map((question, qindex) => {
                                if (userrole !== "admin") {
                                    if (question.deleted === "0") {
                                        return (<div className={"displayFlex"}>
                                            <div className="col-12 card mb-3">
                                                <div className="blueLine"></div>
                                                <div className="mt-1">
                                                    <div className="col-12 formElements">
                                                        <div className="col-6">
                                                            <div
                                                                className="mb-3 formPadding"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <div className="textLeft">
                                                                    <label className="labelQuestion">
                                                                        Question Description
                                                                    </label>
                                                                </div>
                                                                <div className=" textLeft">
                                                                    <p>{question.questionName}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(this.state.FormContents.question[qindex].questionType === "String" || !this.state.FormContents.question[qindex].questionType) && (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <div className="fullWidth">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Answers
                                                                            </label>
                                                                        </div>
                                                                        <div className="">
                                                                            <input
                                                                                className="form-check-input inputForm"
                                                                                onChange={this.onChange(qindex)}
                                                                                type="text"
                                                                                placeholder="Enter Question"
                                                                                name="answer"
                                                                                id="answer"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                        {(this.state.FormContents.question[qindex].questionType === "Number" || !this.state.FormContents.question[qindex].questionType) && (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <div className="fullWidth">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Answers
                                                                            </label>
                                                                        </div>
                                                                        <div className="">
                                                                            <input
                                                                                className="form-check-input inputForm"
                                                                                onChange={this.onChange(qindex)}
                                                                                type="number"
                                                                                placeholder="Enter Question"
                                                                                name="answer"
                                                                                id="answer"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                        {this.state.FormContents.question[qindex].questionType === "Dropdown" && (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <div className="fullWidth">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Answers
                                                                            </label>
                                                                        </div>
                                                                        <div className=" ">
                                                                            <select
                                                                                className="dropdownMenu"
                                                                                value={this.state.location}
                                                                                name="answer"
                                                                                id="answer"
                                                                                onChange={this.onChange(qindex)}
                                                                            >
                                                                                <option value="">Select...
                                                                                </option>
                                                                                {this.state.FormContents.question[qindex].options.map((options, index) => {
                                                                                    return (<option
                                                                                        value={options.option}>
                                                                                        {options.option}
                                                                                    </option>);
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                        {this.state.FormContents.question[qindex].questionType === "Radio" && (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <div className="">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Answers
                                                                            </label>
                                                                        </div>
                                                                        <div className=" textLeft">
                                                                            {
                                                                                question.options.map((opt, index) => {
                                                                                    return (<div>
                                                                                        <input
                                                                                            type="radio"
                                                                                            key={opt.option}
                                                                                            id={opt.option}
                                                                                            checked={opt.option === question.answer}
                                                                                            value={opt.option}
                                                                                            onClick={this.onChangeRadio(qindex, opt.option)}
                                                                                        />
                                                                                        <label
                                                                                            for={opt.option}
                                                                                            style={{marginLeft: "5px"}}>
                                                                                            {opt.option}
                                                                                        </label>
                                                                                    </div>);
                                                                                })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                        {this.state.FormContents.question[qindex].questionType === "Date" && (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <div className="">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Date
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ">
                                                                            <input
                                                                                className="form-check-input inputForm"
                                                                                onChange={this.onChange(qindex)}
                                                                                type="date"
                                                                                placeholder="Pick Date"
                                                                                name="answer"
                                                                                id="answer"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                        {this.state.FormContents.question[qindex].questionType === "Time" && (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <div className="">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Time
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ">
                                                                            <input
                                                                                className="form-check-input inputForm"
                                                                                onChange={this.onChange(qindex)}
                                                                                type="time"
                                                                                placeholder="Pick Time"
                                                                                name="answer"
                                                                                id="answer"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}

                                                        {this.state.FormContents.question[qindex].questionType === "Image" && (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}
                                                            >
                                                                <div className="">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Image
                                                                            </label>
                                                                        </div>
                                                                        <div className="textLeft">
                                                                            <Button
                                                                                variant="primary"
                                                                                className="primaryButton"
                                                                                type="submit"
                                                                                onClick={() => this.openCamera(qindex)}
                                                                            >
                                                                                Take Photo
                                                                            </Button>
                                                                            {this.state.openCamera == true && qindex == this.state.qindex && (
                                                                                <Camera
                                                                                    // isFullscreen = {true}
                                                                                    onTakePhoto={(dataUri) => {
                                                                                        this.handleTakePhoto(dataUri);
                                                                                    }}
                                                                                />)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);
                                    }
                                } else {
                                    return (<div className={"displayFlex"}>
                                        <div className="col-11 card mb-3">
                                            <div className="blueLine"></div>
                                            <div className="mt-1">
                                                <div className="col-12 formElements">
                                                    <div className="col-6">
                                                        <div
                                                            className="mb-3 formPadding"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <div className="textLeft">
                                                                <label className="labelQuestion">
                                                                    Question Description
                                                                </label>
                                                            </div>
                                                            <div className=" textLeft">
                                                                <p>{question.questionName}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {(this.state.FormContents.question[qindex].questionType === "String" || !this.state.FormContents.question[qindex].questionType) && (
                                                        <div
                                                            className="col-6 formElements"
                                                            style={{
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            <div className="fullWidth">
                                                                <div
                                                                    className="formPadding"
                                                                    controlId="formBasicPassword"
                                                                >
                                                                    <div className="textLeft">
                                                                        <label className="labelQuestion">
                                                                            Answers
                                                                        </label>
                                                                    </div>
                                                                    <div className="">
                                                                        <input
                                                                            className="form-check-input inputForm"
                                                                            onChange={this.onChange(qindex)}
                                                                            type="text"
                                                                            placeholder="Enter Question"
                                                                            name="answer"
                                                                            id="answer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}

                                                    {(this.state.FormContents.question[qindex].questionType === "Number" || !this.state.FormContents.question[qindex].questionType) && (
                                                        <div
                                                            className="col-6 formElements"
                                                            style={{
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            <div className="fullWidth">
                                                                <div
                                                                    className="formPadding"
                                                                    controlId="formBasicPassword"
                                                                >
                                                                    <div className="textLeft">
                                                                        <label className="labelQuestion">
                                                                            Answers
                                                                        </label>
                                                                    </div>
                                                                    <div className="">
                                                                        <input
                                                                            className="form-check-input inputForm"
                                                                            onChange={this.onChange(qindex)}
                                                                            type="number"
                                                                            placeholder="Enter Question"
                                                                            name="answer"
                                                                            id="answer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}

                                                    {this.state.FormContents.question[qindex].questionType === "Dropdown" && (
                                                        <div
                                                            className="col-6 formElements"
                                                            style={{
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            <div className="fullWidth">
                                                                <div
                                                                    className="formPadding"
                                                                    controlId="formBasicPassword"
                                                                >
                                                                    <div className="textLeft">
                                                                        <label className="labelQuestion">
                                                                            Answers
                                                                        </label>
                                                                    </div>
                                                                    <div className=" ">
                                                                        <select
                                                                            className="dropdownMenu"
                                                                            value={this.state.location}
                                                                            name="answer"
                                                                            id="answer"
                                                                            onChange={this.onChange(qindex)}
                                                                        >
                                                                            <option value="">Select...</option>
                                                                            {this.state.FormContents.question[qindex].options.map((options, index) => {
                                                                                return (<option
                                                                                    value={options.option}>
                                                                                    {options.option}
                                                                                </option>);
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}

                                                    {
                                                        this.state.FormContents.question[qindex].questionType === "Radio" &&
                                                        (
                                                            <div
                                                                className="col-6 formElements"
                                                                style={{
                                                                    marginBottom: "10px",
                                                                }}>
                                                                <div className="">
                                                                    <div
                                                                        className="formPadding"
                                                                        controlId="formBasicPassword"
                                                                    >
                                                                        <div className="textLeft">
                                                                            <label className="labelQuestion">
                                                                                Answers
                                                                            </label>
                                                                        </div>
                                                                        <div className=" textLeft">
                                                                            {question.options.map((opt, index) => {
                                                                                return (<div>
                                                                                    <input
                                                                                        type="radio"
                                                                                        key={opt.option}
                                                                                        id={opt.option}
                                                                                        checked={opt.option === question.answer}
                                                                                        value={opt.option}
                                                                                        onClick={this.onChangeRadio(qindex, opt.option)}
                                                                                    />
                                                                                    <label for={opt.option}
                                                                                           style={{marginLeft: "5px"}}>
                                                                                        {opt.option}
                                                                                    </label>
                                                                                </div>);
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    {this.state.FormContents.question[qindex].questionType === "Date" && (
                                                        <div
                                                            className="col-6 formElements"
                                                            style={{
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            <div className="">
                                                                <div
                                                                    className="formPadding"
                                                                    controlId="formBasicPassword"
                                                                >
                                                                    <div className="textLeft">
                                                                        <label className="labelQuestion">
                                                                            Date
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ">
                                                                        <input
                                                                            className="form-check-input inputForm"
                                                                            onChange={this.onChange(qindex)}
                                                                            type="date"
                                                                            placeholder="Pick Date"
                                                                            name="answer"
                                                                            id="answer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}

                                                    {this.state.FormContents.question[qindex].questionType === "Time" && (
                                                        <div
                                                            className="col-6 formElements"
                                                            style={{
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            <div className="">
                                                                <div
                                                                    className="formPadding"
                                                                    controlId="formBasicPassword"
                                                                >
                                                                    <div className="textLeft">
                                                                        <label className="labelQuestion">
                                                                            Time
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ">
                                                                        <input
                                                                            className="form-check-input inputForm"
                                                                            onChange={this.onChange(qindex)}
                                                                            type="time"
                                                                            placeholder="Pick Time"
                                                                            name="answer"
                                                                            id="answer"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}

                                                    {this.state.FormContents.question[qindex].questionType === "Image" && (
                                                        <div
                                                            className="col-6 formElements"
                                                            style={{
                                                                marginBottom: "10px",
                                                            }}
                                                        >
                                                            <div className="">
                                                                <div
                                                                    className="formPadding"
                                                                    controlId="formBasicPassword"
                                                                >
                                                                    <div className="textLeft">
                                                                        <label className="labelQuestion">
                                                                            Image
                                                                        </label>
                                                                    </div>
                                                                    <div className="textLeft">
                                                                        <Button
                                                                            variant="primary"
                                                                            className="primaryButton"
                                                                            type="submit"
                                                                            onClick={() => this.openCamera(qindex)}
                                                                        >
                                                                            Take Photo
                                                                        </Button>
                                                                        {this.state.openCamera == true && qindex == this.state.qindex && (
                                                                            <Camera
                                                                                // isFullscreen = {true}
                                                                                onTakePhoto={(dataUri) => {
                                                                                    this.handleTakePhoto(dataUri);
                                                                                }}
                                                                            />)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.FormContents.question[qindex].deleted === '0' &&
                                            <div className={"col-1 displayBlock marginAuto"}>
                                                <div>
                                                    <img
                                                        src={deleteicon}
                                                        onClick={() => this.handleOpen(qindex)}
                                                        // onClick={() => this.delete(qindex)}
                                                        className="delimg"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>}
                                        {this.state.FormContents.question[qindex].deleted !== '0' &&
                                            <div className={"col-1 displayBlock marginAuto"}>
                                                <div>
                                                    <img
                                                        src={undo}
                                                        onClick={() => this.handleOpen1(qindex)}
                                                        // onClick={() => this.delete(qindex)}
                                                        className="delimg"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>}
                                    </div>);
                                }
                            })}


                        <div>
                            {this.state.error === 'error' &&
                                <p className={"errortext"}>Fill Answers for all Questions...</p>}
                            {userrole === 'operator' && <div style={{float: "right"}}>
                                <Button
                                    variant="success"
                                    className="successButton"
                                    type="submit"
                                    onClick={this.onSubmitAnswers}
                                >
                                    Submit
                                </Button>
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to delete it?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Can't be recovered once delete. Sure want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button className="secondButton" onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="primaryButton" onClick={this.handleSaveClose}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.show1} onHide={this.handleClose1} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to undo it?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Recovering the deleted data. Sure want to undo?</Modal.Body>
                <Modal.Footer>
                    <Button className="secondButton" onClick={this.handleClose1}>
                        Close
                    </Button>
                    <Button variant="primary" className="primaryButton" onClick={this.handleSaveClose1}>
                        Undo
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }
}

export default Answers;
