import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import {API_URL, userrole, email, branchId, branchName} from "../util/Constants";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import "./Formlisting.css";
import Header from "../Header/Header";
import history from "../util/History";
import Qna from "../../images/qna.png";
import {Routes, Route, useNavigate} from 'react-router-dom';
import axios from "axios";
import Aos from 'aos';
import 'aos/dist/aos.css';

const FormContents = [
        {
            id : '1',
            FormTitle : 'Line Clearance',
        },
        {
            id : '2',
            FormTitle : 'Cleaning and Sanitization',
        },
        {
            id : '3',
            FormTitle : 'Hourly Data',
        },
        {
            id : '4',
            FormTitle : 'Machine Status',
        },
        {
            id : '5',
            FormTitle : 'Wastage Data',
        },
    ]


class FormListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      i: 1,
      FormContents: [{}],
      QuestionDescription: [
        {
          Options: [{}],
        },
      ],
      //   Options:[{}],
      index: 0,
      // userrole : '',
    };

  }

  componentDidMount() {

      Aos.init({duration: 500});
      console.log(email);
      console.log(userrole);
      console.log(branchId);
      console.log(branchName);
      if(!email || email == '' || !userrole || userrole == '' || !branchId || branchId == '' || !branchName || branchName == '')
      {
          window.location.href = '/error';
      }
      else {
          this.getAllForms();
      }
  }

    loginValidation = () =>{
      let postObj = 'balajikannan@cavinkare.com';
      // let postObj = 'operator@cavinkare.com';
        axios
            .get(API_URL + "mail/" + postObj)
            .then((data) => {
                console.log(data);
                console.log(data.status);
                if (data.status === 200 && data.data != '') {
                    console.log("successmsg");
                    console.log(data.data);
                    localStorage.setItem("userName", data.data.userName);
                    localStorage.setItem("userMail", data.data.userMail);
                    localStorage.setItem("userrole", data.data.userrole);
                    this.getAllForms();
                } else {
                    window.location.href = "/error";
                }
            })
            .catch((error) => {
                console.error(error);
                window.location.href = "/error";
            });
    }

  getAllForms = () =>{
      axios
          .get(API_URL + "all/"+branchId )
          .then((data) => {
              console.log(data.data);
              console.log(data.status);
              if (data.status === 200) {
                  const state = this.state;
                  state.FormContents = data.data;
                  this.setState(state);
                  console.log(this.state.FormContents);
              } else {
                  // window.location.href = "/error"
              }
          })
          .catch((error) => {
              console.error(error);
              // window.location.href = "/error"
          });
  }

 navigateToCreateForm = () =>{
    console.log('navigate working...');
    window.location.href = '/createform';
 }

 navigateToShowAnswers = () =>{
    console.log('navigate working...');
    window.location.href = '/showanswer';
 }

 navigateToAnswerPage = (id) =>{
    console.log(id);
    localStorage.setItem("formId", id);
    window.location.href = '/answers';
 }

  onChangeTitle = (e) => {
    console.log(e.target.value);
    console.log(e.target.name);
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
    console.log(this.state);
  };

  onChange = (qindex) => (e) => {
    console.log(e.target.value);
    console.log(e.target.name);
    const state = this.state.QuestionDescription[qindex];
    state[e.target.name] = e.target.value;
    this.setState(state);
    console.log(this.state);
    if (this.state.FormTitl) {
      console.log(this.state.FormContents[this.state.i - 1]);
      const state = this.state.FormContents[this.state.i - 1];
      state[e.target.name] = e.target.value;
      this.setState(state);
      console.log(this.state);
      return;
      if (e.target.id === "others") {
        const state = this.state;
        state[e.target.name + "Others"] = "others";
        state[e.target.name] = e.target.value;
        this.setState(state);
        console.log(this.state);
      } else {
        const state = this.state;
        state[e.target.name + "Others"] = "";
        state[e.target.name] = e.target.value;
        this.setState(state);
        console.log(this.state);
      }
    } else {
      console.log("Fill the title");
    }
  };
  addOptions = (qindex, index) => (e) => {
    console.log(qindex);
    console.log(index);
    const state = this.state.QuestionDescription[qindex];
    state.Options.push({});
    this.setState(state);
    console.log(this.state);
    // const state = this.state;
    // state.index = Number(state.index)+1;
    // state.Options.push({});
    // this.setState(state);
    // console.log(this.state);
  };
  addQuestionCard = (e) => {
    const state = this.state.QuestionDescription;
    state.push({ Options: [{}] });
    this.setState(state);
    console.log(this.state);
  };

  onChangeOptions = (qindex, index) => (e) => {
    console.log(qindex);
    console.log(index);
    const state = this.state.QuestionDescription[qindex].Options;
    state[index] = e.target.value;
    this.setState(state);
    console.log(this.state);
  };


  render() {
    return (
      <div className="mainDiv">
        <Header />
        <div className="col-12 bg-colorForm1">
          <div className="col-6 titleContent">
            <p className="titleText">Welcome</p>
            <p>Please take few minutes to fill these details.</p>
          </div>
          <div className="col-6 imgcls">
            <img src={Qna} className="qnaImg" alt="" />
          </div>
        </div>

            <div className="backgroundColorDiv">
            <div className="row  bg-colorForm2 formListRowMargin">

                {userrole === 'admin' &&
                    <div className="textRight">
                    <Button
                        variant="primary"
                        className="primaryButton"
                        onClick={this.navigateToCreateForm}
                    >
                        Create Form
                    </Button>
                    <Button
                        variant="primary"
                        className="ansButton"
                        onClick={this.navigateToShowAnswers}
                    >
                        Answers
                    </Button>
                </div>}

                {this.state.FormContents.map(
                (FormContents, qindex) => {
                    return (

                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4" data-aos = "zoom-in-down">
                    {/* <Link className="Linkto"
                        to={{
                            pathname: "/answers",
                            state : {id : FormContents.id }// your data array of objects
                        }}
                        > */}
                        <div className="card mb-3 cardHeight"
                        onClick={()=>this.navigateToAnswerPage(FormContents.formId)}
                        >
                        
                            <div className="formListingTitleDiv">
                            <p className="formListingTitle">{FormContents.formName}</p>
                            </div>
                            
                        </div>
                        {/* </Link> */}
                    </div>
                    );
                }
                )}

                {/* <div className="textLeft">
                <Button
                    variant="primary"
                    className="primaryButton"
                    type="submit"
                    onClick={this.addQuestionCard}
                >
                    + Add Question
                </Button>
                </div> */}

            </div>
            </div>

      </div>
    );
  }
}


export default FormListing;
