import React, {Component, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {API_URL, userrole} from "./util/Constants";
import history from "./util/History";
import axios from "axios";

export default function Auth() {
    let { email, role, branchId,branchName } = useParams();
    // let sessionId = "6767875875875875";
    console.log(email);
    console.log(role);
    console.log(branchId);
    console.log(branchName);
    useEffect(()=>{

            if(!email || email == '' || !role || role == '' || !branchId || branchId == '' || !branchName || branchName == '')
            {
                window.location.href = '/error';
            }
            else {
                console.log('success');
                localStorage.setItem('userMail', email);
                localStorage.setItem('userrole', role);
                localStorage.setItem('branchId', branchId);
                localStorage.setItem('branchName', branchName);
                window.location.href = '/form';
                // axios
                //     .get(API_URL + "/api/check?sessionId=" + sessionId)
                //     .then((data) => {
                //         console.log(data);
                //         console.log(data.status);
                //         if (data.status === 200) {
                //             console.log('1');
                //             console.log("successmsg");
                //             console.log(data.data);
                //         } else {
                //             console.log('3');
                //             window.location.href = "/error";
                //         }
                //     })
                //     .catch((error) => {
                //         console.log('4');
                //         // window.location.href = "/error";
                //     });




            //     fetch(API_URL + '/api/check?sessionId=' + sessionId, {
            //         method: 'GET',
            //     }).then(response => response.json())
            //         .then(data => {
            //
            //             if(data.auth == 2){
            //                 console.log('1');
            //                 // history.push('/upload/' + sessionId);
            //             } else if(data.auth){
            //                 console.log('2');
            //                 // history.push('/search/' + sessionId);
            //             } else {
            //                 console.log('3');
            //                 // window.location = API_URL + '/api/login';
            //             }
            //
            //         })
            //         .catch(error => {
            //             console.error(error)
            //         });
            }



    },[])


    return (
        <div>
        </div>
    );

}
// class Auth extends Component {
//
//     constructor(props) {
//         super(props);
//
//         console.log(props.match);
//         let sessionId = '';
//         if(sessionId != '') {
//                     // sessionId = this.props.match.params.sessionId;
//                   console.log(sessionId);
//         } else {
//                     window.location = API_URL + '/api/login';
//                 }
//
//
//     }
//
//
//     render() {
//         return (
//             <div>
//             </div>
//         );
//     }
// }
//
// export default Auth;
