import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import history from "./Components/util/History";
import Forms from './Components/Formview/Form';
import Auth from './Components/Auth';
import Formlisting from './Components/Formlisting/Formlisting';
import Answer from './Components/Answers/Answer';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import EditPage from "./Components/Editpage/EditPage";
import ErrorPage from "./Components/Error/Error";
import ShowAnswer from "./Components/ShowAnswers/ShowAnswer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Router history={history}>

        <div className="App bg-color">
            <Routes>
                <Route path="/" element={< Auth />}/>
                <Route path="/:email/:role/:branchId/:branchName" element={< Auth />}/>
                <Route path="/form" element={< Formlisting />}/>
                <Route path="/createform" element={< Forms />}/>
                <Route path="/answers" element={< Answer />}/>
                <Route path="/showanswer" element={< ShowAnswer />}/>
                <Route path="/edit" element={< EditPage />}/>
                <Route path="/error" element={< ErrorPage />}/>
                <Route path="*" element={<ErrorPage />} />;
            </Routes>

        </div>

    </Router>
);

// If you want to start measuring performance in your app, pass a function to
// log results (for example: reportWebVitals(console.log)) or send to an
// analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();