import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import errorImg from "../../images/404.png";
import "../Formview/Form.css";
import "react-html5-camera-photo/build/css/index.css";
import "../Formview/Form.css";

class Error extends Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     isAuthenticated : true
        // };
    }
    handleSubmit = (e) =>
    {
        e.preventDefault();
        // let userMail = localStorage.getItem("userMail");
        // let userrole = localStorage.getItem("userrole");
        // if(userMail === ''|| userrole === '' || !userMail || !userrole)
        // {
        //     const state = state;
        //     state.isAuthenticated = false;
        //     this.setState(state);
        // }
        // else
        // {
        //     const state = state;
        //     state.isAuthenticated = true;
        //     this.setState(state);
        //     window.location.href = '/'+userMail+"/"+userrole ;
        // }
            window.location.href = '/form' ;

    }
    render() {
        return (
            <div className="container-fluid row containerStyl">
                <div className="errorBodyStyl">
                    <img src={errorImg} className='errorImg'  alt={"error"}/>
                    <h2 className='oops'>Oops !!!</h2>
                    <h5 className='text-muted'>Something went wrong. Please try again after sometime.</h5>
                    <div className='buttondiv'>
                        <Button variant="primary"
                                className="primaryButton" type="button" onClick={this.handleSubmit}
                                >
                            Go Back
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Error;
