
//Local
// export const API_URL = 'http://localhost:8085/forms/api/';

//prod
export const API_URL = '/forms/api/';

//localstorage global data
export const email = localStorage.getItem("userMail");
export const userrole = localStorage.getItem("userrole");
export const branchId = localStorage.getItem("branchId");
export const branchName = localStorage.getItem("branchName");

// export const AUTH_ENABLED = true;
// export const sample = true;



