import React, { Component } from "react";
import {
    Form,
    Button,
    Container,
    Row,
    Col,
    Card,
    Navbar,
    Offcanvas,
    Nav,
    NavDropdown,
    Dropdown,
    FloatingLabel,
    Grid,
} from "react-bootstrap";
import axios from "axios";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import "../Formview/Form.css";
import "./ShowAnswer.css"
import Header from "../Header/Header";
import Qna from "../../images/qna.png";
import {API_URL, branchId, userrole} from "../util/Constants";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import * as ReactBootStrap from "react-bootstrap";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import {Modal} from "react-bootstrap";
import eye from "../../images/eye.png";





class ShowAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            i: 1,
            FormTitle:'',
            basicQuestion: false,
            FormContents: {
                qna:[{}]
            },
            QuestionDescription: [
                {
                    questionName:'',
                    questionType:'',
                    options: [{option:''}],
                    deleted: 0,
                    status: 'active'
                },
            ],
            //   options:[{}],
            index: 0,
            error: '',
            pageNumber: 1,
            pageSize: 10,
            loading: false,
            tableData: [],
            columns: [],
            show: false
        };
    }

    componentDidMount() {
        localStorage.removeItem("formId");
        this.getAllAnswers();
        // if(!userrole || userrole != "admin")
        // {
        //     window.location.href = "/error";
        // }
    }

    handleClose = () => {
        // e.preventDefault();
        const state = this.state;
        state.show=false;
        this.setState(state);
    }
    handleShow = (e,row) => {
        e.preventDefault();
        console.log(row);
        const state = this.state;
        state.FormContents = row;
        state.show=true;
        this.setState(state);
        console.log(this.state);
    }

    getAllAnswers = () => {
        const postObj = {
            branchId : branchId
        }
        axios.post(API_URL + "getAllAnswersByBranch", postObj)
            .then((data) => {
                console.log(data);
                console.log(data.status);
                if (data.status === 200 && data.data != '') {
                    console.log("successmsg");
                    console.log(data.data);
                    const state =this.state;
                    state.tableData = data.data;
                    state.loading = true;
                    this.setState(state);
                    console.log(this.state.tableData);
                } else {
                    const state = this.state;
                    state.loading = true;
                    state.tableData = [];
                    this.setState(state);
                }
            })
            .catch((error) => {
                console.error(error);
                window.location.href = "/error";
            });
    }

    viewComponent = () => {
        console.log("entering ")
    }





    render() {
        const columns = [
            { dataField: "id", text: "S No.",
                formatter: (cell, row, rowIndex) => {
                    return ((this.state.pageNumber-1) * this.state.pageSize + (rowIndex + 1));
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '60px', textAlign: 'center' };
                }},
            { dataField: "formName", text: "Form Name", sort: true, filter: textFilter() },
            { dataField: "operatorName", text: "Operator Name", sort: true, filter: textFilter() },
            { dataField: "timestamp", text: "Timestamp", sort: true, filter: textFilter() },
            // {dataField: 'status', text: 'Status', hidden: !(vendorAccount),
            //     formatter: (cellContent, row) =>{ return(<Form.Check type="switch" id="custom-switch" defaultChecked={cellContent} onClick={()=> updateStatus(row)}></Form.Check>);},
            // },
            {dataField: 'labourDob', text: "Edit", isDummyField:true,
                formatter: (cellContent, row) =>{ return(
                    <div style={{textAlign :"center"}}>
                        <img src={eye}
                             style={{cursor: "pointer"}}
                             className={"eyeImage"}
                             width={35}
                             height={33}
                             onClick ={(e) => this.handleShow(e,row)}
                        >

                        </img>
                    </div>
                );},
            },
        ];


        return (
            <div className="mainDiv">
                <Header />
                <div style={{margin: "50px 150px"}}>
                {/*<Card style={{border: "0px", background: "transparent"}}>*/}
                {/*    <Container>*/}
                        <div style={{marginBottom: "0px !important", background: "white"}}>
                            <div className="blueLine"></div>
                            {this.state.loading ? (<BootstrapTable bootstrap4
                                                        keyField="id"
                                                        data={this.state.tableData}
                                                        columns={columns}
                                                        wrapperClasses="table-responsive"
                                                        // pagination={paginationFactory(this.state.options)}
                                                        filter={filterFactory()}
                            />) : (
                                <ReactBootStrap.Spinner animation="border" />
                            )}
                        </div>
                    {/*</Container>*/}
                {/*</Card>*/}
                </div>
                <Modal
                    show={this.state.show}
                    onHide={() => this.handleClose()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="modal-header" closeButton>
                        <Modal.Title>Answers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div >
                            {this.state.FormContents &&
                                <div className="container  bg-colorForm formRowMargin">
                                    <div className="">
                                        <div className="card mb-3">
                                            <div className="blueLine"></div>
                                            <div className="col-12">
                                                <div className="formPadding1 -displayFlex" controlId="formBasicEmail">
                                                    <div className="col-12 displayFlex">
                                                        <div className="col-4 textLeft marginTopAuto">
                                                            <label htmlFor="likedProducts" className="labelQuestion">
                                                                Form Title
                                                            </label>
                                                        </div>
                                                        <div className="col-8 textLeft marginTopAuto">
                                                            <div className="options radio-label-products">
                                                                <div className="verticalmiddle">
                                                                    <p className={"mbZero"}>{this.state.FormContents.formName}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.FormContents &&
                                            this.state.FormContents.basicQuestion === "true" && (
                                                <div className="container bg-colorFormTable1">
                                                    <div className="blueLine"></div>
                                                    <table style={{ marginBottom: "0px" }}>
                                                        <tr>
                                                            <td>Date</td>
                                                            <td colSpan={3} className="verticalmiddle">
                                                                <p className={"mbZero"}>{this.state.FormContents.selectedDate}</p>
                                                            </td>
                                                            <td>Start time</td>
                                                            <td className="verticalmiddle">
                                                                <p className={"mbZero"}>{this.state.FormContents.startTime}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shift</td>
                                                            <td colSpan={3} style={{ width: "50%" }} className="verticalmiddle">
                                                                <p className={"mbZero"}>{this.state.FormContents.shift}</p>
                                                            </td>
                                                            <td>End time</td>
                                                            <td style={{ width: "20%" }} className="verticalmiddle">
                                                                <p className={"mbZero"}>{this.state.FormContents.endTime}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Machine No.</td>
                                                            <td colSpan={5} className="verticalmiddle">
                                                                <p className={"mbZero"}>{this.state.FormContents.machineNo}</p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )}
                                        {this.state.FormContents.qna.map((question, qindex) => {
                                            return (
                                                <div className="card mb-3">
                                                    <div className="blueLine"></div>
                                                    <div className="mt-1">
                                                        <div className="col-12 formElements">
                                                            <div className="col-8">
                                                                <div className="mb-3 formPadding"
                                                                     controlId="formBasicEmail">
                                                                    <div className="textLeft">
                                                                        <label htmlFor="likedProducts"
                                                                               className="labelQuestion">
                                                                            Question Description
                                                                        </label>
                                                                    </div>
                                                                    <div className="verticalmiddle">
                                                                        <p className={"mbZero"}>{question.questionName}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 formPadding">
                                                                <div className="textLeft">
                                                                    <label htmlFor="likedProducts"
                                                                           className="labelQuestion">
                                                                        Answers
                                                                    </label>
                                                                </div>
                                                                <div className="verticalmiddle">
                                                                    <p className={"mbZero"}>{question.answer}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleClose()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

export default ShowAnswer;
