import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import "../Formview/Form.css";
import Header from "../Header/Header";
import Qna from "../../images/qna.png";
import {API_URL, userrole} from "../util/Constants";

class EditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            i: 1,
            // basicQuestion: false,
            // FormContents: [
            //     {}
            // ],
            // question: [
            //     {
            //         options: [{}]
            //     }
            // ],
            //   options:[{}],
            index: 0
        };
    }

    componentDidMount() {
        if(userrole && userrole == 'admin'){
            const formId = localStorage.getItem("formId");
            this.getFormContents(formId);
            console.log(formId);
        }
        else{
            window.location.href = "/error";
        }
    }

    getFormContents = (formId) => {
        axios
            .get(API_URL + "id/" + formId)
            .then((data) => {
                console.log(data.data);
                console.log(data.status);
                if (data.status === 200) {
                    const state = this.state;
                    state.FormContents = data.data;
                    this.setState(state);
                    console.log(this.state.FormContents);
                } else {
                    window.location.href = "/error"
                }
            })
            .catch((error) => {
                console.error(error);
                window.location.href = "/error"
            });
    };

    onSubmitForm = (e) =>{
        e.preventDefault();
        if(!this.state.FormContents.formName ||this.state.FormContents.formName == '')
        {
            console.log("No title");
            const state = this.state;
            state.error = 'error';
            this.setState(state);
            return;
        }
        else{
            for(let x of this.state.FormContents.question){
                if(!x.questionName || x.questionName == '' || !x.questionType || x.questionType == ''){
                    const state = this.state;
                    state.error = 'error';
                    this.setState(state);
                    return;
                }
                if(x.questionType == 'Dropdown' || x.questionType == 'Radio'){
                    for(let y of x.options){
                        if(!y.option || y.option == '' ){
                            const state = this.state;
                            state.error = 'error';
                            this.setState(state);
                            return;
                        }
                    }
                }
            }
        }
        let currentdate = new Date();
        let date =
            currentdate.getFullYear() +
            "-" +
            String(currentdate.getMonth()+1).padStart(2,"0") +
            "-" +
            String(currentdate.getDate()).padStart(2,"0") +
            " " +
            String(currentdate.getHours()).padStart(2,"0") +
            ":" +
            String(currentdate.getMinutes()).padStart(2,"0") +
            ":" +
            String(currentdate.getSeconds()).padStart(2,"0");
        const state = this.state;
        state.error = '';
        state.FormContents.updatedDate = date;
        state.FormContents.updatedBy = localStorage.getItem('userMail');
        this.setState(state);
        console.log(this.state.FormContents);
        console.log(this.state.FormContents.question);

        axios.put(API_URL + 'update',this.state.FormContents)
            .then(data => {
                console.log(data);
                console.log(data.status);
                if(data.status === 200){
                    console.log("successmsg");
                    window.location.href='/form ';

                }else {
                    window.location.href = "/error"
                }
            }).catch((error) => {
            console.error(error)
            window.location.href = "/error"
        })





    }

    onChangeTitle = (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        const state = this.state.FormContents;
        state[e.target.name] = e.target.value;
        this.setState(state);
        console.log(this.state.FormContents);
    };

    onChangeCheckbox = (e) => {
        console.log(e.target.checked);
        console.log(e.target.name);
        if(e.target.checked === true)
        {
            const state = this.state.FormContents;
            state[e.target.name] = 'true';
            this.setState(state);
            console.log(this.state.FormContents);
        }
        else {
            const state = this.state.FormContents;
            state[e.target.name] = 'false';
            this.setState(state);
            console.log(this.state.FormContents);
        }

    };

    onChange = (qindex) => (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        const state = this.state.FormContents.question[qindex];
        state[e.target.name] = e.target.value;
        this.setState(state);
        console.log(this.state.FormContents);
        if (this.state.FormTitl) {
            console.log(this.state.FormContents[this.state.i - 1]);
            const state = this.state.FormContents[this.state.i - 1];
            state[e.target.name] = e.target.value;
            this.setState(state);
            console.log(this.state);
            return;
            if (e.target.id === "others") {
                const state = this.state;
                state[e.target.name + "Others"] = "others";
                state[e.target.name] = e.target.value;
                this.setState(state);
                console.log(this.state);
            } else {
                const state = this.state;
                state[e.target.name + "Others"] = "";
                state[e.target.name] = e.target.value;
                this.setState(state);
                console.log(this.state);
            }
        } else {
            console.log("Fill the title");
        }
    };

    addoptions = (qindex, index) => (e) => {
        console.log(qindex);
        console.log(index);
        const state = this.state.FormContents.question[qindex];
        state
            .options
            .push({});
        this.setState(state);
        console.log(this.state);
        // const state = this.state; state.index = Number(state.index)+1;
        // state.options.push({}); this.setState(state); console.log(this.state);
    };

    addQuestionCard = (e) => {
        const state = this.state.FormContents.question;
        state.push({options: [{}]});
        this.setState(state);
        console.log(this.state);
    };

    onChangeoptions = (qindex, index) => (e) => {
        console.log(qindex);
        console.log(index);
        const state = this.state.FormContents.question[qindex].options;
        state[index].option = e.target.value;
        this.setState(state);
        console.log(this.state);
        // const state = this.state.options; state[index] = e.target.value;
        // this.setState(state); console.log(this.state);
    };



    render() {
        return (
            <div className="mainDiv">
                <Header/>
                <div className="col-12 bg-colorForm1">
                    <div className="col-6 titleContent">
                        <h4>Employee Satisfactory Survey</h4>
                        <p>Please take few minutes to fill these details.</p>
                    </div>
                    <div className="col-6 imgcls">
                        <img src={Qna} className="qnaImg" alt=""/>
                    </div>
                </div>
                <div className="backgroundColorDiv">
                    {this.state.FormContents &&
                        <div className="container  bg-colorForm formRowMargin">
                            <div className="">
                                <div className="card mb-3">
                                    <div className="blueLine"></div>
                                    <div className="col-12">
                                        <div className="formPadding1 -displayFlex" controlId="formBasicEmail">
                                            <div className="col-12 displayFlex">
                                                <div className="col-4 textLeft marginTopAuto">
                                                    <label htmlFor="likedProducts" className="labelQuestion">
                                                        Form Title
                                                    </label>
                                                </div>
                                                <div className="col-8 textLeft marginTopAuto">
                                                    <div className="options radio-label-products">
                                                        <div className="form-check ">
                                                            <input
                                                                className="form-check-input inputForm"
                                                                onChange={this.onChangeTitle}
                                                                type="text"
                                                                placeholder="Enter Title"
                                                                name="formName"
                                                                value={this.state.FormContents.formName}
                                                                id="FormTitle"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-12"} style={{display: "inline-flex"}}>
                                    <div className={"col-lg-9 col-md-6 col-sm-6"} style={{float: "left"}}></div>
                                    <div className={"col-lg-3 col-md-6 col-sm-6"} style={{float: "right"}}>
                                        <div style={{display: "inline-flex"}}>
                                            <input
                                                style={{margin: "auto 5px"}}
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={this.onChangeCheckbox}
                                                placeholder="Enter Question"
                                                value={this.state.FormContents.basicQuestion}
                                                checked={this.state.FormContents.basicQuestion === "true" ? true : false}
                                                name="basicQuestion"
                                                id="basicQuestion"/>
                                            <p style={{fontSize: "13px", margin: "auto"}}>Need the basic Questions</p>
                                        </div>
                                    </div>
                                </div>
                                {this.state.FormContents.question.map((question, qindex) => {
                                        return (
                                            <div className="card mb-3">
                                                <div className="blueLine"></div>
                                                <div className="mt-1">
                                                    <div className="col-12 formElements">
                                                        <div className="col-8">
                                                            <div className="mb-3 formPadding"
                                                                 controlId="formBasicEmail">
                                                                <div className="textLeft">
                                                                    <label htmlFor="likedProducts"
                                                                           className="labelQuestion">
                                                                        Question Description
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ">
                                                                    <input
                                                                        className="form-check-input inputForm"
                                                                        onChange={this.onChange(qindex)}
                                                                        type="text"
                                                                        placeholder="Enter Question"
                                                                        name="questionName"
                                                                        id="questionName"
                                                                        value={question.questionName}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 formPadding">
                                                            <div className="textLeft">
                                                                <label htmlFor="likedProducts"
                                                                       className="labelQuestion">
                                                                    Data Type
                                                                </label>
                                                            </div>
                                                            <div className="textLeft">
                                                                <div className="form-check dropdownDiv">
                                                                    <select
                                                                        className="dropdownMenu"
                                                                        value={question.questionType}
                                                                        name="questionType"
                                                                        id="questionType"
                                                                        onChange={this.onChange(qindex)}>
                                                                        <option value="">Select</option>
                                                                        <option value="Number">Number</option>
                                                                        <option value="String">String</option>
                                                                        <option value="Dropdown">Dropdown</option>
                                                                        <option value="Radio">Radio</option>
                                                                        <option value="Date">Date</option>
                                                                        <option value="Time">Time</option>
                                                                        {/*<option value="Image">Image</option>*/}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(this.state.FormContents.question[qindex].questionType === "String" || this.state.FormContents.question[qindex].questionType === "Number" || !this.state.FormContents.question[qindex].questionType) && (
                                                        <div
                                                            className="col-12 formElements"
                                                            style={{
                                                                marginBottom: "10px"
                                                            }}>
                                                            <div className="col-8">
                                                                <div className="mb-3 formPadding"
                                                                     controlId="formBasicPassword">
                                                                    <div className="textLeft">
                                                                        <label htmlFor="likedProducts"
                                                                               className="labelQuestion">
                                                                            Answers
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ">
                                                                        <input
                                                                            className="form-check-input inputForm"
                                                                            onChange={this.onChange(qindex)}
                                                                            type="text"
                                                                            placeholder="Enter Question"
                                                                            name="Answers"
                                                                            id="Answers"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {(this.state.FormContents.question[qindex].questionType === "Dropdown" || this.state.FormContents.question[qindex].questionType === "Radio") && this.state.FormContents.question[qindex].options.map((options, index) => {
                                                        return (
                                                            <div
                                                                className="col-12 formElements"
                                                                style={{
                                                                    marginBottom: "10px"
                                                                }}>
                                                                <div className="col-8">
                                                                    <div className="mb-3 formPadding"
                                                                         controlId="formBasicPassword">
                                                                        <div className="textLeft">
                                                                            <label htmlFor="likedProducts"
                                                                                   className="labelQuestion">
                                                                                Answers
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ">
                                                                            <input
                                                                                className="form-check-input inputForm"
                                                                                onChange={this.onChangeoptions(qindex, index)}
                                                                                type="text"
                                                                                placeholder="Enter Question"
                                                                                value={options.option}
                                                                                name="options"
                                                                                id="options"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {index === this.state.index && (
                                                                    <div className="col-4 formPadding">
                                                                        <div className="textLeft">
                                                                            <div
                                                                                className="form-check dropdownDiv"
                                                                                style={{
                                                                                    marginTop: "25px"
                                                                                }}>
                                                                                <Button
                                                                                    variant="primary"
                                                                                    className="primaryButton"
                                                                                    onClick={this.addoptions(qindex, index)}>
                                                                                    +Answer
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}

                                                    {this.state.FormContents.question[qindex].questionType === "Date" && (
                                                        <div
                                                            className="col-12 formElements"
                                                            style={{
                                                                marginBottom: "10px"
                                                            }}>
                                                            <div className="col-8">
                                                                <div className="mb-3 formPadding"
                                                                     controlId="formBasicPassword">
                                                                    <div className="textLeft">
                                                                        <label htmlFor="likedProducts"
                                                                               className="labelQuestion">
                                                                            Date
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ">
                                                                        <input
                                                                            className="form-check-input inputForm"
                                                                            onChange={this.onChange(qindex)}
                                                                            type="date"
                                                                            placeholder="Pick Date"
                                                                            name="Date"
                                                                            id="Date"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {this.state.FormContents.question[qindex].questionType === "Time" && (
                                                        <div
                                                            className="col-12 formElements"
                                                            style={{
                                                                marginBottom: "10px"
                                                            }}>
                                                            <div className="col-8">
                                                                <div className="mb-3 formPadding"
                                                                     controlId="formBasicPassword">
                                                                    <div className="textLeft">
                                                                        <label htmlFor="likedProducts"
                                                                               className="labelQuestion">
                                                                            Time
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ">
                                                                        <input
                                                                            className="form-check-input inputForm"
                                                                            onChange={this.onChange(qindex)}
                                                                            type="time"
                                                                            placeholder="Pick Time"
                                                                            name="Time"
                                                                            id="Time"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {this.state.FormContents.question[qindex].questionType === "Image" && (
                                                        <div
                                                            className="col-12 formElements"
                                                            style={{
                                                                marginBottom: "10px"
                                                            }}>
                                                            <div className="col-8">
                                                                <div className="mb-3 formPadding"
                                                                     controlId="formBasicPassword">
                                                                    <div className="textLeft">
                                                                        <label htmlFor="likedProducts"
                                                                               className="labelQuestion">
                                                                            Image
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ">

                                                                        {/* <input
                                    className="form-check-input inputForm"
                                    onChange={this.onChange(qindex)}
                                    type="time"
                                    placeholder="Pick Time"
                                    name="Time"
                                    id="Time"
                                    /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                <div className="">
                                    {this.state.error === 'error' &&
                                        <p className={"errortext"}>Empty values detected !!! Fill the entire form</p>}
                                    <div className="textLeft" style={{float: "left"}}>
                                        <Button
                                            variant="primary"
                                            className="primaryButton"
                                            type="submit"
                                            onClick={this.addQuestionCard}>
                                            + Add Question
                                        </Button>
                                    </div>
                                    <div style={{float: "right"}}>
                                        <Button
                                            variant="success"
                                            className="successButton"
                                            type="submit"
                                            onClick={this.onSubmitForm}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default EditPage;
