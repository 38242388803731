// import logo from './logo.svg';
import Forms from './Components/Formview/Form';
import Formlisting from './Components/Formlisting/Formlisting';
import history from "./Components/util/History";
import Answer from './Components/Answers/Answer';
import EditPage from './Components/Editpage/EditPage';
import ErrorPage from './Components/Error/Error';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Components/Formview/Form.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {createBrowserHistory} from 'history';

function App() {
    // localStorage.setItem("userrole", 'admin');
    return (


    <div className="App bg-color">
    </div>
    );
}

export default App;
